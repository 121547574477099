body {
  margin: 0;
}

.logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 120px;
}
